// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.primary {
    color: $primary;
}

.info {
    color: $info;
}

.btn-primary, .btn-info {
    font-weight: 500;
    color: white;
    font-size: 25px;
}

.btn-primary.active {

}


.btn-light {
    border-color: black;
    border: 1.5px solid;

    &:hover {
        border-color: black;
    }
}

.btn-sm {
    font-size: 15px;
}

.section {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

blockquote {
    font-weight: 100;
    font-size: 2rem;
    line-height: 1.4;
    position: relative;
    margin: 0;
    padding: .5rem;
}

.navbar-dark {
    background: black !important;
    @include media-breakpoint-down(md) {
        .navbar-brand {
            width: 200px !important;
        }
    }

    padding-top: 2rem;
    padding-bottom: 2rem;
}

.navbar-dark .navbar-nav .nav-link {
    color: white;
    font-weight: 500;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .nav-link.active {
    color: $primary;
}

blockquote:before,
blockquote:after {
    position: absolute;
    color: black;
    font-size: 4rem;
    width: 2rem;
    height: 2rem;
}

blockquote:before {
    content: '“';
    left: 0rem;
    top: -2rem;
}

blockquote:after {
    content: '”';
    right: 0rem;
    bottom: 1rem;
}

cite {
    line-height: 3;
    text-align: left;
}


.section-primary {
    background: black;

    .section-title {
        font-size: 25px;
        text-align: center;
        color: white;
        font-weight: 500;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        @include media-breakpoint-up(md) {
            font-size: 30px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.line-primary {
    width: 90%;
    height: 10px;
    background: black;

    &.full {
        width: 100%;
    }
}

.line.primary {
    height: 5px;
    background: $primary;

    &.full {
        width: 100%;
    }
}

#list-vlogs {
    background: #f2f4f6;

    &.list-articles {
        background: white;

        .card-title {
            color: black;
            min-height: auto;
        }
    }

    .default-card-video {
        background: #f2f4f6;
    }

    .card-body {

    }

    @include media-breakpoint-down(md) {
        .card-body {
            padding: 0;
        }
    }
}

.list-courses {
    .card {
        text-align: center;

        .card-body {
            background: #d7d7d7;

            div {
                line-height: 1;
            }

            .card-title {
                font-size: 20px;
                font-weight: 500;


                @include media-breakpoint-up(md) {
                    font-size: 42px;
                }
            }

            .line-primary {
                margin-top: 1rem;
                margin-bottom: 1rem;

                @include media-breakpoint-up(md) {
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                }
            }

            .description {
                font-size: 18px;
                font-weight: 500;
                min-height: 100px;

                @include media-breakpoint-up(md) {
                    font-size: 20px;
                }
            }

            .requirements {
                margin-top: 2rem;

                div {
                    font-size: 15px;
                    font-weight: 500;
                }
            }

            .level {
                font-size: 15px;
                font-weight: 500;
                margin-top: 1rem;
            }

            .price {
                font-size: 25px;
                font-weight: 500;
            }
        }


    }
}

#homepage {
    .newsletter {
        .btn-primary {
            font-size: 14px;
        }

        .title {
            font-weight: 500;
            font-size: 25px;


            @include media-breakpoint-up(lg) {
                font-size: 40px;
            }

        }

        img {
            width: 100px;

            @include media-breakpoint-up(lg) {
                width: 200px
            }
        }
    }

    iframe {
        width: 100%;
        height: 225px;

        @include media-breakpoint-up(md) {
            height: 325px;
        }
    }
}

#article-show {
    .italic {
        font-style: italic;
    }

    .section-title {
        text-align: center;
        color: black;
        font-size: 25px;
        font-weight: 500;
        line-height: 1.2;
        padding-top: 2rem;

        @include media-breakpoint-up(md) {
            font-size: 40px;
            padding-top: 3rem;
        }
    }

    .section-tagline {
        text-align: center;
        color: black;
        font-size: 15px;
        font-weight: 500;
        margin-top: 2rem;
        line-height: 1;
        padding-bottom: 1.5rem;
        padding-top: 1rem;
        font-style: italic;

        @include media-breakpoint-up(md) {
            font-size: 20px;
            padding-bottom: 1.25rem;
        }
    }

    @include media-breakpoint-down(sm) {
        .container.py-5 {
            img {
                max-width: 100% !important;
                height: auto !important;
            }
        }
    }
}

#course-welcome {

    .main-banner {
        background-image: url("/img/banner-course-video-edit.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: calc(0.635 * 99vw)
    }

    .lessons {
        .section-title {
            font-size: 30px;
            font-weight: 500;
            line-height: 1;
            padding-top: 2rem;
            padding-bottom: 3rem;

            @include media-breakpoint-up(md) {
                font-size: 50px;
                padding-top: 4rem;
                padding-bottom: 5rem;
            }
        }


        .progress {
            border-radius: 0;
            position: relative;

            .value {
                text-align: center;
                position: absolute;
                width: 100%;
                font-weight: 500;
            }
        }

        .list-lessons {
            .lesson {
                background: #e9e9e9;

                .icon {
                    padding: 0.5rem;
                }

                .lesson-number {
                    background: $primary;
                    color: white;
                    font-size: 50px;
                    padding: 0.5rem 2.5rem;
                }

                .about-lesson {
                    padding-left: 0.5rem;

                    .lesson-title {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 1;

                        @include media-breakpoint-up(md) {
                            font-size: 30px;
                        }
                    }

                    .lesson-description {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 1;


                        @include media-breakpoint-up(md) {
                            font-size: 18px;
                        }
                    }
                }

            }
        }
    }


}


#course-lesson {

    .main-banner {
        background-image: url("/img/banner-course-video-edit.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: calc(0.635 * 99vw)
    }

    .lesson {
        .section-title {
            font-size: 25px;
            font-weight: 500;

            @include media-breakpoint-up(md) {
                font-size: 35px;
            }
        }

        .section-description {
            font-weight: 500;
            font-size: 15px;

            h6 {
                font-size: 20px;
                font-weight: 500;
            }

            @include media-breakpoint-up(md) {
                font-size: 17px;
            }
        }

        .section-files {
            margin-top: 2rem;

            .file {
                background: #d7d7d7;
                padding: 0.5rem 1.5rem;
            }

            .file-title {
                font-size: 15px;
                font-weight: 500;

                @include media-breakpoint-up(md) {
                    font-size: 30px;
                }


            }

            .btn {
                font-size: 10px;
            }
        }

        .arrows-pagination {
            margin-top: 2rem;

            .arrow {
                font-size: 20px;
                font-weight: 500;
                border: 1px solid #D7D7D7;
                padding: 0.5rem 1rem;

                img {
                    width: 75px;
                }

                .arrow-title {
                    display: none;

                    @include media-breakpoint-up(md) {
                        display: block;
                    }
                }


                &.arrow-left {
                    .arrow-title {
                        margin-left: 1rem;
                    }
                }

                &.arrow-right {
                    .arrow-title {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}

#course {
    &.video-edit {
        .page-title {
            background: #1eb0f4;
            font-weight: 500;
        }

        h1 {
            margin: 0;
            padding: 0.5rem 0;
            text-align: center;
            font-size: 20px;
            color: white;

            @include media-breakpoint-up(md) {
                font-size: 42.5px;
            }
        }

        .main-banner {
            background-image: url("/img/banner-course-video-edit.png");
            background-repeat: no-repeat;
            background-size: cover;
            height: calc(0.635 * 99vw)
        }

        .video-section {
            background: black;
            text-align: center;
            color: white;

            h3, h3 span {
                font-weight: 500;
            }

            iframe {
                height: 300px;
            }

            @include media-breakpoint-up(md) {
                iframe {
                    height: 500px;
                }

                h3 {
                    font-size: 30px;
                }
            }
        }

        .quote {
            blockquote {
                font-weight: 500;
                font-style: italic;
                font-size: 20px;

                @include media-breakpoint-down(md) {
                    text-align: center;

                }

                @include media-breakpoint-up(md) {
                    font-size: 30px;
                }
            }

            @include media-breakpoint-down(md) {

                blockquote:before {
                    left: -2rem !important;
                    top: -4rem !important;
                }

                blockquote:after {
                    left: 15rem !important;
                    top: 9rem !important;
                }
            }
        }

        .about-course {
            background: $primary;

            .title-section, .description-section {
                font-weight: 500;
                text-align: center;
                color: white;
            }

            .title-section {
                font-size: 32px;
            }

            .description-section {
                font-size: 22px;
            }
        }

        .lessons {
            .lesson-title {
                .lesson-number {
                    color: $primary;
                    font-size: 35px;
                    font-weight: 500;
                }

                .lesson-description {
                    color: black;
                    font-size: 24px;
                    font-weight: 500;
                }
            }

            ul {
                list-style-type: none;
                padding-left: 1rem;

                li {
                    font-weight: 500;
                    font-size: 16px;

                    &:before {
                        $size: 18px;
                        content: '';
                        display: inline-block;
                        height: $size;
                        width: $size;
                        background-size: $size;
                        background-image: url("/img/green-sign.png");
                        background-repeat: no-repeat;
                        margin-right: 5px;


                    }

                    @include media-breakpoint-up(md) {
                        &.no-bullet {
                            &:before {
                                background-image: none;
                            }
                        }

                        .hide {
                            opacity: 0;
                        }

                    }

                    @include media-breakpoint-up(md) {
                        font-size: 18px;
                    }
                }
            }
        }

        .banner-primary {
            background: $primary;
            color: white;
            text-align: center;

            h3, h2 {
                margin: 0;
                font-weight: 500;
            }

            h3 {
                font-size: 22px;
                @include media-breakpoint-up(md) {
                    font-size: 32px;
                }
            }

            h2 {
                font-size: 20px;
                @include media-breakpoint-up(md) {
                    font-size: 32px;
                }
            }
        }
    }
}

#login-page {
    .login {
        .btn {
            font-size: 13px;
        }

        .section-title {
            font-size: 20px;
            font-weight: 500;
            text-align: center;
            margin-top: 1rem;

            @include media-breakpoint-up(md) {
                margin-top: 0;
                font-size: 40px;
                text-align: left;
            }
        }
    }


    .section-description {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
            text-align: left;
            font-size: 25px;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }

    .lost-password {
        text-decoration: none;
        font-weight: 500;
        color: black;
    }
}

.buy {
    h2 {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
    }
}

.free-button {
    font-size: 30px;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 500;
    background: $primary;
    color: white;
    width: 160px;

    &.rotate {
        @include media-breakpoint-up(md) {
            -ms-transform: rotate(-23); /* IE 9 */
            -webkit-transform: rotate(-23deg); /* Chrome, Safari, Opera */
            transform: rotate(-23deg); /* Standard syntax */
            transform-origin: bottom left; /* Prevent the bottom from shifting */
        }
    }
}

#mini-cards {
    .others-courses {
        .card {
            text-align: center;

            .card-body {
                background: #d7d7d7;

                div {
                    line-height: 1;
                }

                .card-title {
                    font-size: 20px;
                    font-weight: 500;


                    @include media-breakpoint-up(md) {
                        font-size: 30px;
                    }
                }

            }


        }
    }
}

footer {
    background: black;
    color: white;
    font-size: 18px;
    font-weight: 500;

    .is-icon {
        width: 30px !important;
    }

    .social-media {
        div {
            margin-right: 0.5rem;
        }
    }

    a {
        text-decoration: none;
        color: white;

        &:hover {
            color: $primary
        }
    }

    .mail {
        font-style: italic;
    }

    .fa-envelope {
        margin-right: 0.5rem;
    }

    h5 {
        font-size: 20px;
        font-weight: 500;
    }

    .links {
        margin-top: 1rem;

        > {
            font-size: 18px;
            font-weight: 500;
        }


    }
}

figure.image {
    display: inline-block;
    border: 1px solid gray;
    margin: 0 2px 0 1px;
    background: #f5f2f0;
}

figure.align-left {
    float: left;
}

figure.align-right {
    float: right;
}

figure.image img {
    margin: 8px 8px 0 8px;
}

figure.image figcaption {
    margin: 6px 8px 6px 8px;
    text-align: center;
}


/*
 Alignment using classes rather than inline styles
 check out the "formats" option
*/

img.align-left {
    float: left;
}

img.align-right {
    float: right;
}

/* Basic styles for Table of Contents plugin (toc) */
.mce-toc {
    border: 1px solid gray;
}

.mce-toc h2 {
    margin: 4px;
}

.mce-toc li {
    list-style-type: none;
}

#articles-list {
    padding-top: 3rem;

    h1 {
        text-align: center;
    }

    .card-title {
        min-height: 43px;
    }

    a {
        color: black;
        text-align: center;
        text-align: center;
    }

    .card-body {
        text-align: center;
    }

    .card-video {
        .card-text {
            min-height: 80px;
        }
    }
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}

.btn.btn-dark {
    color: white !important;

    &.active {
        background: #f4631e !important;
    }

}

.default-card-video {
    .action {
        border: 1px solid #ddd;
        padding: 5px;
        border-radius: 4px;
        text-decoration: none;
        color: black !important;
    }

    .card-title {
        text-align: center;
        min-height: 45px;

        a {
            color: black;

        }
    }
}

.card-title {
    a {
        text-decoration: none;
    }
}

.js-cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    z-index: 999999;


    .flex.items-center.justify-between.flex-wrap {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;

        .cookie-consent__message {
            margin: 0 !important;
            padding-top: 8px;
        }
    }
}

#contact {
    #header {
        background-image: url("/images/bg-header-contact.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @include media-breakpoint-up(lg) {
            padding-bottom: 10rem;
        }


        @include media-breakpoint-down(sm) {
            background-position: 63%;
        }

        .hero {
            .section-1 {
                @include media-breakpoint-up(sm) {
                    width: 50%;
                }

                @include media-breakpoint-down(sm) {
                    width: 70%;
                }
            }
        }

        #about, h2 {
            color: white;
        }

        .main-title {
            background: #00b7d2;
            padding: 0.25rem;
            color: black;
            text-align: center;
            font-weight: bold;
        }

        h2 {
            font-weight: 700;
            margin-top: 1rem;
        }


        .about-item {
            margin: 1rem 0;

            .about-item-icon {
                margin: auto 0;
                padding-right: 1rem;
                width: auto;
                text-align: right;

                @include media-breakpoint-up(sm) {
                    width: 100px;
                }
            }

            .about-item-details {
                padding-left: 1rem;

                div:first-child {
                    font-weight: bold;
                }
            }

            .email {
                color: $primary;
            }
        }
    }


    .form, .map {
        @include media-breakpoint-up(sm) {
            width: 50%;
        }
    }

    #form-section {
        background-image: url("/images/bg-form-section.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .container {
            background: #F8F8F8;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }

    #testimonials-section {
        background: $primary;

        .image {
            position: relative;

            img:last-child {
                position: absolute;
                top: 0;
            }

        }


        .title {
            width: 20%;
        }

        .image {
            width: 30%;
        }

        .description {
            width: 50%;
        }

        @include media-breakpoint-down(md) {
            .title {
                width: 100%;
            }

            .image {
                width: 40%;
            }

            .description {
                width: 60%;
            }

        }

        @include media-breakpoint-down(sm) {
            .title, .image, .description {
                width: 100%;
            }
        }
    }
}


.masterclass {
    &.creatie-video {
        * {
            font-family: "Nunito";
        }


        .header {
            position: relative;

            .content {
                position: absolute;
                left: 10%;
                top: 20%;
            }
        }

        .section-1 {
            p {
                font-size: 19px;
            }
        }


        .about-masterclass {
            margin-top: 2rem;
            margin-bottom: 2rem;


            .sessions {
                margin-top: 2rem;

                .session-name, .session-number {
                    text-align: center;
                    font-weight: 700;
                }

                .session-number {
                    margin-top: 1rem;
                    font-size: 20px;
                }

                .session-name {
                    font-size: 25px;
                }

                .title-content {
                    text-align: center;
                }

                .about-content {
                    margin: auto 0;


                }
            }


        }


        @include media-breakpoint-up(sm) {
            .header {
                .content {
                    left: 10%;
                    top: 20%;

                    h1, h1 span {
                        font-size: 40px;
                    }

                    h1 span.primary {
                        font-size: 50px;
                    }
                }
            }

            .section-1 {
                p {
                    font-size: 25px;
                }
            }

            .about-masterclass {
                .sessions {
                    .about-content {

                    }
                }

            }
        }

        @include media-breakpoint-up(xl) {
            .header {
                .content {
                    left: 10%;
                    top: 20%;

                    h1, h1 span {
                        font-size: 90px;
                    }

                    h1 span.primary {
                        font-size: 100px;
                    }
                }
            }


            .section {
                .title {
                    font-size: 50px;
                    font-weight: bold;
                }

                .description {
                    font-size: 25px;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .header {
                .content {
                    top: 5px;

                    a {
                        font-size: 13px
                    }
                }
            }
        }


        //optimized

        .font-weight-bold {
            font-weight: bold;
        }

        blockquote h3 {
            font-weight: bold;
        }

        blockquote:after {
            bottom: 0rem;
        }

        .line.primary {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }

        .section {
            .title, .description {
                text-align: center;
            }

            .title {
                font-size: 28px;
                line-height: 35px;
                font-weight: 600;
                margin-bottom: 20px;
            }

            .description {
                font-size: 19px;
            }
        }

        ul {
            list-style-type: none;
            padding-left: 3px;

            li {
                font-size: 19px;
                margin-bottom: 5px;
            }
        }

        ul.bolder {
            li {
                font-weight: 600;
            }
        }

        svg {
            margin-right: 4px;

            &.fa-check {
                color: green;
            }

            &.fa-xmark {
                color: red;
            }
        }

        .sessions {
            .title-content {
                margin-bottom: 2rem;
            }
        }

        .session:not(:last-child) {
            margin-bottom: 1rem;
        }

        .why {
            p {
                font-size: 19px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .last-words {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            font-weight: bold;
            text-align: center;
            font-size: 19px;
        }

        h6 {
            font-weight: bold;
            text-align: center;
            font-size: 20px;

        }

        .fa-circle-right {
            color: $primary;
        }

        .cards {
            .card {
                text-align: center;

                .header {
                    text-align: center;
                    color: $primary;
                    font-size: 35px;
                }

                .content {
                    font-weight: bold;
                    font-size: 19px;
                }
            }
        }

        #checkout {
            .card {
                margin-bottom: 1rem;

                .card-body {

                }

                ul.bolder {
                    margin-bottom: 0 !important;

                    li {
                        font-size: 20px;
                        margin-bottom: 0 !important;
                    }
                }
            }
        }

        @include media-breakpoint-up(sm) {
            .line.primary {
                margin-top: 2rem;
                margin-bottom: 2rem;
            }

            .session, .for-you {
                ul li {
                    font-size: 15px;
                }

                img {
                    max-width: 130px;
                }
            }

            .why {
                h6 {
                    font-size: 25px;

                }

                p {
                    font-size: 18px;
                }
            }

            .last-words {
                font-size: 20px;
            }


        }
        @include media-breakpoint-up(md) {
            .section {
                .title {
                    font-size: 40px;
                    margin-bottom: 30px;
                }

                .description {
                    font-size: 25px;
                }
            }

            .sessions {
                .title-content {
                    margin-bottom: 0;
                }
            }

            .sessions {
                .title-content {
                    margin-bottom: 4rem;
                }
            }

        }
        @include media-breakpoint-up(lg) {
            .session, .for-you {
                ul li {
                    font-size: 16px;
                }
            }
        }
        @include media-breakpoint-up(xl) {
            .line.primary {
                margin-top: 3rem;
                margin-bottom: 3rem;
            }
        }
    }

}


button, button:focus, button:active {
    outline: 0 !important;
    box-shadow: none !important;
}

.iframe-container, iframe {
    height: 355px !important;
    width: 100% !important;

    @include media-breakpoint-down(sm) {
        height: 210px !important;
        margin: 1rem 0;
    }
}

#checkout {
    background: black;

    h2:first-child, label {
        color: white;
    }
}

#thank-you {
    p {
        font-weight: 600;
        font-size: 19px;
    }

    .card-header {
        background: $primary;
        color: white;
        text-align: center;
    }

    .card-body {
        text-align: center;
    }

    &.quiz {

        .form-check-label,.form-check {
            text-align: left!important;
            font-weight: bold;
        }

        h5 {
            text-align: left;
            color: $primary;
            font-weight: bold;
        }
    }
}
